(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/timelimit-message/assets/javascripts/timelimit-message.js') >= 0) return;  svs.modules.push('/components/accountservices/timelimit-message/assets/javascripts/timelimit-message.js');

'use strict';

svs = svs || {};
svs.accountservices = svs.accountservices || {};
svs.accountservices.timelimit_message = svs.accountservices.timelimit_message || {};
const minutesToRemainBeforeMessage = 5;
const startCountdownIfTimeIsLessThen = 240;
class TimeLimitMessage {
  constructor() {
    if (svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
      var _svs$accountservices$;
      if ((_svs$accountservices$ = svs.accountservices.timelimit_message.data) !== null && _svs$accountservices$ !== void 0 && _svs$accountservices$.disableTimeLimitMessage) {
        return;
      }
      let {
        remainingTime
      } = svs.accountservices.timelimit_message.data;
      const {
        hasTimeLimits
      } = svs.accountservices.timelimit_message.data;
      this.hasShownEndOfTimeLimit = false;
      this.hasShownAlmostDepleated = false;
      this.log = new svs.core.log.Logger('components:timelimit-message');
      if (hasTimeLimits && remainingTime !== null && remainingTime !== Infinity && remainingTime <= startCountdownIfTimeIsLessThen) {
        this.shouldShowMessage(remainingTime);
        setInterval(() => {
          remainingTime--;
          this.shouldShowMessage(remainingTime);
        }, 60000);
      }
    }
  }
  shouldShowMessage(remainingTime) {
    if (remainingTime <= minutesToRemainBeforeMessage) {
      if (remainingTime <= 0) {
        svs.core.userSession.get('limit_noRemainingTime', (err, data) => {
          if (!data) {
            if (!this.hasShownEndOfTimeLimit) {
              svs.ui.dialog.api.add(this.getDialog(true));
              this.hasShownEndOfTimeLimit = true;
            }
            svs.core.userSession.set('limit_noRemainingTime', true, err => {
              this.hasShownEndOfTimeLimit = true;
              err && this.log.error('Error storing timelimit value in session', err);
            });
          }
        });
      } else {
        svs.core.userSession.get('limit_timeLimitAlmostDepleated', (err, data) => {
          if (!data) {
            if (!this.hasShownAlmostDepleated) {
              svs.ui.dialog.api.add(this.getDialog());
              this.hasShownAlmostDepleated = true;
            }
            svs.core.userSession.set('limit_timeLimitAlmostDepleated', true, err => {
              err && this.log.error('Error storing timelimit value in session', err);
            });
          }
        });
      }
    }
  }
  getDialog(isNoRemainingTime) {
    const actions = isNoRemainingTime ? [{
      title: 'Stäng'
    }, {
      title: 'Logga ut',
      callback: () => {
        svs.utils.url.navigate("".concat(svs.core.urlMapping.get('logout'), "?returnUrl=/"));
      }
    }] : [{
      title: 'Okej'
    }];
    const text = isNoRemainingTime ? "N\xE4r du n\xE5tt din tidsgr\xE4ns kan du inte betala f\xF6r  spel p\xE5 Sport & Casino. <br>\n        Se dina inst\xE4llningar under <a href=\"".concat(svs.core.urlMapping.get('myProfileLimits'), "\">Mina gr\xE4nser</a>.") : 'Om mindre än fem minuter når du din tidsgräns per dag och kan inte betala för spel på Sport & Casino.';
    const title = isNoRemainingTime ? 'Du har nått din tidsgräns' : 'Snart når du din tidsgräns';
    return new svs.ui.dialog.Confirm({
      actions,
      icon: 'time-limit',
      area: svs.ui.dialog.area.POPUP,
      branding: svs.ui.dialog.branding[svs.components.lbUtils.branding],
      message: [{
        text
      }],
      title,
      type: svs.ui.dialog.type.DEFAULT
    });
  }
}
svs.accountservices.timelimit_message.timeLimitMessage = new TimeLimitMessage();

 })(window);